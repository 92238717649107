<template>
  <div>
    <div
      class="text-center header-content height-res-tab-small-400 height-res-400"
      style="height: 500px"
    >
      <div
        class="
          d-flex
          justify-content-center
          container-standar
          h-100 h-res-tab-small-80
        "
      >
        <div class="pos-absolute bottom-10">
          <p class="text-white font-18 font-weight-5 mb-2">Category - 5 min</p>
          <h2 class="text-white font-38 font-weight-6 mb-2">
            {{ detail.title }}
          </h2>
          <div
            class="row d-flex justify-content-center"
            style="margin-top: 10px; margin-bottom: 10px"
          >
            <div class="col-auto">
              <b-avatar src="@/assets/images/avatar.png" size="3rem"></b-avatar>
            </div>
            <div class="col-auto text-left">
              <span
                class="text-white font-16 font-weight-6"
                style="margin-bottom: -30px"
                >Admin</span
              >
              <br />
              <span class="text-white font-14 font-weight-4">{{
                detail.publish_date
              }}</span>
            </div>
          </div>
          <br /><br />
        </div>
      </div>
    </div>
    <div class="cotainer-standar text-center" style="margin-top: -100px">
      <div class="container-standar mt-10 mb-10 pad-20">
        <div class="row">
          <div class="col-lg-12 pad-20 mb-25" style="border-radius: 20px">
            <b-row>
              <b-col cols="12" class="pad-20">
                <b-img
                  fluid
                  :src="detail.picture_path"
                  alt="image"
                  style="
                    border-radius: 20px;
                    height: 500px;
                    width: 100%;
                    object-fit: cover;
                  "
                ></b-img>
              </b-col>
              <b-col cols="12" lg="2" md="2" class="pt-4">
                <div style="font-size: 50px">
                  <ShareNetwork
                    v-for="network in networks"
                    :network="network.network"
                    :key="network.network"
                    :url="sharing.url"
                    :title="sharing.title"
                    :description="sharing.description"
                    :quote="sharing.quote"
                    :hashtags="sharing.hashtags"
                  >
                    <b-icon
                      :icon="network.icon"
                      class="rounded-circle p-2"
                      style="background: #f0f2f5; font-color: #000000"
                    ></b-icon
                    ><br />
                  </ShareNetwork>

                  <!-- <b-icon icon="book" class="rounded-circle p-2" style="background: #F0F2F5; font-color:#000000;"></b-icon><br>
                                    <b-icon icon="twitter" class="rounded-circle p-2" style="background: #F0F2F5; font-color:#000000;"></b-icon><br> -->
                </div>
              </b-col>
              <b-col cols="12" lg="8" md="8" sm="12">
                <div class="pad-20">
                  <p
                    class="text-justify mt-10 font-20"
                    v-html="detail.content"
                  ></p>
                </div>
              </b-col>
              <b-col cols="12" lg="2" md="2"> </b-col>
            </b-row>
          </div>
          <div class="col-lg-12 pad-20">
            <hr class="rounded" />
          </div>
          <div class="col-lg-12 pad-20" style="border-radius: 20px">
            <div class="text-left pad-10">
              <span
                class="
                  d-inline-block
                  bor-bot-1
                  width-100
                  pt-2
                  pb-2
                  font-22 font-weight-7
                  mb-20
                  text-primary
                "
                >Berita Terkait</span
              >
              <b-row>
                <b-col
                  cols="12"
                  lg="4"
                  md="6"
                  sm="12"
                  v-for="(berita, index) in related"
                  :key="index"
                >
                  <b-link
                    :to="'/berita/' + berita.slug"
                    @click.native="$router.go()"
                  >
                    <b-card
                      border-variant="transparent"
                      class="suggest-img"
                      v-b-hover="handleHover"
                      @mouseover="mouseOver(index)"
                      :class="hover && i == index ? 'shadow p-0' : 'p-0'"
                      :style="
                        hover && i == index
                          ? 'transform: scale(1.1); transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1); border-radius: 20px;'
                          : 'border-0'
                      "
                    >
                      <b-row>
                        <b-col cols="12">
                          <b-img
                            style="
                              border-radius: 20px;
                              height: 300px;
                              width: 100%;
                              object-fit: cover;
                            "
                            :src="berita.picture_path"
                          ></b-img>
                          <b-badge class="my-3" href="#" variant="primary"
                            >Category</b-badge
                          >
                          - <span>{{ berita.created }}</span> <br />
                          <span class="font-17 font-weight-7">{{
                            berita.title | shotDescription(50)
                          }}</span>
                          <br />
                          <p>{{ berita.content | shotDescription(100) }}</p>
                          <b-avatar
                            src="@/assets/images/avatar.png"
                            size="2rem"
                            class="mt-3 mr-2"
                          ></b-avatar>
                          <span class="font-16 font-weight-4"> Admin</span
                          ><br />
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-link>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: {},
      related: [],
      hover: true,
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "facebook",
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "linkedin",
          color: "#007bb5",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "twitter",
          color: "#1da1f2",
        },
      ],
      i: null,
    };
  },
  computed: {
    sharing() {
      let data = {};
      data = {
        url: "https://siopapua.papua.go.id/berita/" + this.detail.id,
        title: this.detail.title,
        description: "Description",
        quote: "quote",
        hashtags: "hastags",
      };
      return data;
    },
  },
  async mounted() {
    this.$store.commit("SET_BG", false);
    await this.getDetail(this.$route.params.id);
    this.getBerita();
  },
  methods: {
    async getDetail(id) {
      await this.$axios.get("/news/" + id).then((response) => {
        this.detail = response.data.data;
      });
    },
    getBerita() {
      this.$axios.get("/news").then((response) => {
        //  this.related = response.data.filter( (value, index) => index < 5 && this.detail.id != value.id)
        this.related = response.data.data.filter((value, index) => index < 5);
      });
    },
    goDetail(i) {
      this.$router.push({ name: "Berita Detail", params: { id: i } });
    },
    handleHover(isHovered) {
      if (isHovered) {
        this.hover = true;
      } else {
        this.hover = false;
      }
    },
    mouseOver(i) {
      this.i = i;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
hr.rounded {
  border-top: 2px solid #bbb;
  border-radius: 5px;
}
</style>
